import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import {
  Calendar,
  Popover,
  DropdownTrigger,
  Icon,
  Text
} from '@junglescout/edna'
import { sendCtaClickEvent } from 'helpers/segment'
import { CalendarDataShape } from 'types/calendar'
import { COMPARISON_TYPES } from 'constants/calendar'

const Row = styled.div`
  display: flex;
  align-items: center;
`
const TriggerContainer = styled(Row)`
  gap: 12px;
`
const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    line-height: 1;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
const StyledPopover = styled(Popover)`
  border-radius: 8px;
  padding: 20px;
`

export const CalendarDropdown = memo(
  ({ calendarData, setDates, width, trackingLocation, disabled }) => {
    const { t } = useTranslation('common')
    const [open, setOpen] = useState(false)

    const {
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
      comparisonType,
      ranges,
      selectedRange,
      minDate,
      maxDate
    } = calendarData

    const onApplyDates = (
      selectedStart,
      selectedEnd,
      _comparedStartDate,
      _comparedEndDate,
      selectedRangeType,
      selectedComparisonType,
      selectedPeriodsAmount
      // eslint-disable-next-line max-params
    ) => {
      setDates({
        customStartDate: selectedStart,
        customEndDate: selectedEnd,
        rangeType: selectedRangeType,
        comparisonType: selectedComparisonType,
        selectedPeriodsAmount
      })
      setOpen(false)
      sendCtaClickEvent({
        text: 'Apply Dates',
        location: trackingLocation,
        extraProps: {
          subtext: selectedRangeType
        }
      })
    }

    const formatDates = (start, end) => {
      if (!start) {
        return t(
          'common:CalendarSelect.labels.selectDateRange',
          'Select date range'
        )
      }

      const startMoment = moment(start)
      const endMoment = moment(end)
      const diff = endMoment.diff(startMoment, 'days')
      const format = 'MMM D, YYYY'
      return diff === 0
        ? startMoment.format(format)
        : `${startMoment.format(format)} - ${endMoment.format(format)}`
    }

    return (
      <StyledPopover
        width="100%"
        align="end"
        open={open}
        onOpenChange={setOpen}
        content={
          <Calendar
            cachedCompareType={comparisonType}
            cachedDateRange={selectedRange?.rangeType}
            cachedPeriodsAmount={calendarData.selectedPeriodsAmount}
            dateRangeOptions={ranges}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            handleApplyDates={onApplyDates}
            handleCancelDates={() => setOpen(false)}
            includeComparePeriodsSelection={Boolean(comparisonType)}
            includeMultiPeriodsComparison={
              comparisonType === COMPARISON_TYPES.previousPeriods
            }
            includeRangeInputs
            includeRangeSelection
            selectsRange
          />
        }>
        <DropdownTrigger width={width} disabled={disabled}>
          <TriggerContainer>
            <Icon name="CALENDAR" />
            <LabelContainer disabled={disabled}>
              <Row>
                {selectedRange?.label && (
                  <Text
                    variant="bodySm"
                    color={comparisonType ? 'grey900' : 'grey600'}
                    fontWeight="500"
                    margin="0 4px 0 0">
                    {`${selectedRange.label}:`}
                  </Text>
                )}
                <Text variant="bodySm" fontWeight="500" margin="0 4px 0 0">
                  {formatDates(startDate, endDate)}
                </Text>
              </Row>
              {comparisonType && (
                <Text variant="bodySm" color="grey600">
                  {`${t(
                    'common:CalendarRanges.comparingTo',
                    'Comparing to'
                  )}: ${formatDates(comparisonStartDate, comparisonEndDate)} `}
                </Text>
              )}
            </LabelContainer>
          </TriggerContainer>
        </DropdownTrigger>
      </StyledPopover>
    )
  }
)

CalendarDropdown.propTypes = {
  calendarData: CalendarDataShape.isRequired,
  setDates: PropTypes.func.isRequired,
  width: PropTypes.node,
  trackingLocation: PropTypes.string,
  disabled: PropTypes.bool
}

CalendarDropdown.defaultProps = {
  width: '360px',
  trackingLocation: undefined,
  disabled: false
}
